import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import AboutUs from "./menuAboutUs";
import People from "./menuPeople";
import PosterExhibition from "./menuPosterExhibition";
import Training from "./menuTraining";
import ContactUs from "./menuContact";
import Stack from "@mui/material/Stack";
import logo from "../images/AdvanceLogoName.svg";
import sfilogo from "../images/SFILogo.jpg";
import { makeStyles } from "@mui/styles";
import MenuDrawer from "./menuDrawer";

const useStyles = makeStyles({
  sfilogo: {
    height: 100,
  },
  logo: {
    height: 100,
  },
  logodrawer: {
    height: 100,
  },
  menu: {
    alignContent: "center",
    justifyContent: "center",
  },
  box: {
    backgroundColor: "#ffffff",
  },
  drawer: {
    padding: 10,
  },
});

const mobileMediaQuery = "(max-width: 1110px)";

export default function NavMenu(props) {
  const classes = useStyles();
  const colour = props.props;
  const [menuControl, setMenuControl] = useState(
    window.matchMedia(mobileMediaQuery).matches
  );

  useEffect(() => {
    const query = window.matchMedia(mobileMediaQuery);

    const handleQueryChange = (queryEvent) => {
      setMenuControl(queryEvent.matches);
    };
    query.addEventListener("change", handleQueryChange);

    return () => {
      query.removeEventListener("change", handleQueryChange);
    };
  }, [menuControl]);

  return (
    <Box className={classes.box}>
      {!menuControl && (
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={3}>
            <a href={"./"}>
              <img
                src={logo}
                className={classes.logo}
                alt="Advance CRT Logo, click for homepage"
              />
            </a>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <AboutUs props={colour} />
              <People props={colour} />
              <PosterExhibition props={colour} />
              <Training props={colour} />
              <ContactUs props={colour} />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <a href={"https://www.sfi.ie/"}>
              <img
                src={sfilogo}
                alt="SFI logo, click to visit SFI website"
                className={classes.sfilogo}
              />
            </a>
          </Grid>
        </Grid>
      )}
      {menuControl && (
        <Grid container spacing={0} direction="row">
          <Grid item xs={2} sm={3} className={classes.drawer}>
            <MenuDrawer />
          </Grid>
          <Grid item xs={10} sm={9}>
            <a href={"./"}>
              <img
                src={logo}
                className={classes.logodrawer}
                alt="Advance CRT Logo, click for homepage"
              />
            </a>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
