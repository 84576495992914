import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paper: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 30px",
    margin: "0 0 10px 0",
  },
  heading: {
    padding: 2,
    textAlign: "center",
  },
  paragraph: {
    padding: 2,
    margin: 2,
  },
});

export default function EventVideoPaper(props) {
  const classes = useStyles();
  const event = props.props;

  return (
    <Paper className={classes.paper}>
      <Typography
        className={classes.heading}
        variant="h3"
        color="#0D3B66"
        tabIndex="0"
      >
        {event.title}
      </Typography>
      <Typography className={classes.paragraph} variant="body1" tabIndex="0">
        {event.description}
      </Typography>
      <iframe
        width="100%"
        height="400"
        src={event.url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </Paper>
  );
}
