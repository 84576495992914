import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import { List, ListItem } from "@mui/material";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
});

const ResearchProgrammePage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "ADVANCE Research Programme";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="body1" tabIndex="0">
              The ADVANCE vision of a hyper-connected world requires a research
              programme that addresses key challenges in advanced communications
              and networking, data driven network management, and ICT
              applications for sustainable communities. These future networks
              will enable customisation, where capabilities adapt to their users
              needs and locations, be it people, Things, or autonomous systems
              and services. Their design will need to consider the complex
              mega-urban environments that place pressure on the social
              well-being and health of residents and of rural environments that
              need to support dispersed communities. This raises major
              challenges and requires new approaches to communication system
              design. ADVANCE’s research programme is focused on:
            </Typography>
            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  Enabling technologies for future wireless/mobile networks and
                  the Internet of Things,
                </ListItem>
                <ListItem>
                  Intelligent and data driven network management and
                  optimisation,{" "}
                </ListItem>
                <ListItem>
                  Internet of Things applications for sustainable communities
                  and independent living and associated social, privacy and
                  security issues.
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default ResearchProgrammePage;
