import React from "react";
import { Box, Typography } from "@mui/material";
import titleBanner from "../images/titlebanner.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  box: {
    width: "100%",
    height: "30vh",
    backgroundImage: `url(${titleBanner})`,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default function TitleBanner(props) {
  const classes = useStyles();
  const title = props.props;

  return (
    <Box className={classes.box}>
      <Typography variant="h1" color="white" tabIndex="0">
        {title}
      </Typography>
    </Box>
  );
}
