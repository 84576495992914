import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TopBar from "../components/topBar";
import TitleBanner from "../components/titleBanner";
import BottomBanner from "../components/bottomBanner";
import url from "../downloadables/newsletters/pdfs/2023May.pdf";
import image from "../downloadables/newsletters/images/2023may.png";
import url2 from "../downloadables/newsletters/pdfs/2023Jun.pdf";
import image2 from "../downloadables/newsletters/images/2023jun.png";
import url3 from "../downloadables/newsletters/pdfs/2023Jul.pdf";
import image3 from "../downloadables/newsletters/images/2023jul.png";
import url4 from "../downloadables/newsletters/pdfs/2023Aug.pdf";
import image4 from "../downloadables/newsletters/images/2023aug.png";
import url5 from "../downloadables/newsletters/pdfs/2023Sep.pdf";
import image5 from "../downloadables/newsletters/images/2023sep.png";
import PdfDownloader from "../components/pdfDownloader";
import Grid from "@mui/material/Grid";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
  stack: {
    position: "realtive",
  },
});

const NewsletterPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "ADVANCE Newsletters";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container className={classes.stack}>
        {dbnewsletters.map((m) => (
          <PdfDownloader props={m} />
        ))}
      </Grid>
      <BottomBanner />
    </>
  );
};

export default NewsletterPage;
