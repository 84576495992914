import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import TopBar from "../components/topBar";
import BottomBanner from "../components/bottomBanner";
import { useParams } from "react-router-dom";
import ProfilePaper from "../components/profilePaper";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {},
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,

    position: "absolute",
  },
});

const ProfilePage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Our People";
  const [dbnewsletters, setDbnewsletters] = useState([]);
  const { id } = useParams();
  const [person, setPerson] = useState();
  const [relations, setRelations] = useState([]);
  const [primarySupervisor, setPrimarySupervisor] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [projectTitle, setProjectTitle] = useState();

  //Get Poster or Newsletter
  useEffect(() => {
    axios
      .post(
        "https://advanceserver-45066d4d7734.herokuapp.com/searchstudentposter",
        {
          uid: id,
        }
      )
      .then(function (response) {
        if (response.data[0]) {
          setDbnewsletters(response.data[0]);
        } else {
          axios
            .get(
              "https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters"
            )
            .then((response) => {
              const newsletterData = response.data;
              newsletterData.sort(function (a, b) {
                return (
                  new Date(b.publicationDate) - new Date(a.publicationDate)
                );
              });

              setDbnewsletters(newsletterData[0]);
            });
        }
      })
      .catch(function (error) {});
  }, []);

  //Get Person Profile
  useEffect(() => {
    console.log(id);
    axios
      .post("https://advanceserver-45066d4d7734.herokuapp.com/searchpeople", {
        uid: id,
      })
      .then(function (response) {
        setPerson(response.data[0]);
        if (response.data[0].isStudent) {
          console.log("is student");
          findStudentRelations(id);
        }
      })
      .catch(function (error) {});
  }, []);

  async function findStudentRelations(data) {
    var foundPrimaries = [];
    var foundSecondaries = [];
    console.log("called");
    axios
      .post(
        "https://advanceserver-45066d4d7734.herokuapp.com/getstudentsupervisors",
        { uid: data }
      )
      .then(function (response) {
        console.log(response.data);
        for (var i = 0; i <= response.data.length - 1; i++) {
          console.log("supervisor " + i + " is " + response.data[i].supervisor);
          if (response.data[i].supervisor == 0) {
            foundPrimaries.push(response.data[i]);
          } else {
            foundSecondaries.push(response.data[i]);
          }
        }
        setPrimarySupervisor(foundPrimaries);
        setSupervisors(foundSecondaries);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Get Supervisory Relationship

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          order={{ xs: 1, sm: 1 }}
          marginRight={1}
          marginLeft={1}
        >
          {person && <ProfilePaper props={person} />}
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters && <SideBar props={dbnewsletters} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default ProfilePage;
