import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import { List, ListItem } from "@mui/material";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import ediImage from "../images/edi.png";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,

    position: "absolute",
  },
  image: {
    width: "100%",
  },
});

const EDIStatementPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Equality, Diversity, and Inclusion Statement";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="body1" tabIndex="0">
              SFI Centre for Research Training in Advanced Networks for
              Sustainable Societies (ADVANCE CRT) is committed to creating a
              leading postgraduate research training centre for all our
              students. We recognise that in order to make this possible, we
              must first provide equal opportunities to all members of our
              diverse community where different cultures, views and ideas can
              meet and be heard.
            </Typography>
            <br aria-hidden="true" />
            <Typography variant="body1" tabIndex="0">
              Our goal is to train the next generation of researchers who strive
              to create sustainable, inclusive and socially informed
              technological solutions for our society. ADVANCE CRT caters to a
              group of researchers operating within the
              multidisciplinary/interdisciplinary interface between Science,
              Technology, Engineering, and Mathematics (STEM) and Arts,
              Humanities and Social Sciences (AHSS). For that purpose, we strive
              to generate a research and training environment in which
              differences of scientific background, professional careers,
              culture, nationality and gender are embraced and everyone is
              included. Our community finds value in connecting with each other
              when we receive training, conduct research or recruit our
              students.
            </Typography>
            <br aria-hidden="true" />

            <img
              src={ediImage}
              className={classes.image}
              alt="Image of UCC ADVANCE Students"
            />
            <br aria-hidden="true" />
            <br aria-hidden="true" />

            <Typography variant="body1" tabIndex="0">
              To reflect this vision, ADVANCE CRT management and governance
              structures, supervisory body and students currently feature:
            </Typography>

            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  40%+ female students in our cohort of 101 students;
                </ListItem>
                <ListItem>
                  An international community of students from 22 countries
                  around the world;{" "}
                </ListItem>
                <ListItem>
                  70% of our students come from non-EU countries;
                </ListItem>
                <ListItem>
                  40% female co-Leads among the CRT academic leadership team;
                </ListItem>
                <ListItem>
                  Almost 40% female supervisors within our 85 supervisory body;
                </ListItem>
                <ListItem>
                  20%+ of our students have a disciplinary background different
                  to STEM;
                </ListItem>
                <ListItem>
                  A minimum of 30% gender balance is respected within our
                  governance committees.
                </ListItem>
              </List>
            </Typography>
            <Typography tabIndex="0">
              We believe in promoting equality, diversity and inclusion in
              everything we do, from how we recruit our students to the types of
              training our students will engage in, including how we frame our
              research agenda. To support fair access, equality of opportunity
              and to enhance participation within ADVANCE, we are implementing
              the following specific actions:
            </Typography>
            <Typography tabIndex="0">
              {" "}
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  We design our training in close collaboration with our
                  students and supervisors, carefully selecting content that is
                  relevant to our diverse community of researchers;
                </ListItem>
                <ListItem>
                  We ensure that training is accessible and inclusive for all,
                  actively seeking female and other underrepresented minorities
                  in science to participate in the delivery of these training
                  activities;
                </ListItem>
                <ListItem>
                  We encourage applications from under-represented groups and
                  international students, particularly from female applicants,
                  to build a diverse student body;
                </ListItem>
                <ListItem>
                  We also welcome applications from those who may be returning
                  to study and will consider all relevant work experience along
                  with any previous academic experience as part of an
                  application;
                </ListItem>
                <ListItem>
                  We recruit students in an open and transparent manner,
                  reducing potential unconscious bias in the decision-making of
                  our gender-balanced shortlisting and interview panels;
                </ListItem>
                <ListItem>
                  Where possible, we offer our students flexible approaches to
                  carrying out their projects to allow those students with
                  family, carer or other commitments to have the opportunity to
                  pursue further education;
                </ListItem>
                <ListItem>
                  We factor gender and diversity in the design and execution of
                  our research projects as appropriate, to avoid gendered
                  innovations;
                </ListItem>
                <ListItem>
                  We seek involvement in our research and training activities of
                  those societal stakeholders and practitioners from industry
                  and civil society that deal on a day-to-day basis with the
                  research problems that we aim to solve.
                </ListItem>
              </List>
            </Typography>
            <Typography variant="body1" tabIndex="0">
              We understand that creating an authentic culture of equality,
              diversity and inclusion is a long-term commitment involving a
              continuous process of self-improvement and critical
              self-evaluation. All ADVANCE CRT members are empowered to
              contribute to this process. As we grow, we must reflect on what we
              have done that has worked well and what could be improved to make
              informed decisions on our future direction to improve the
              experience for everyone.
            </Typography>
            <br aria-hidden="true" />

            <Typography variant="body1" tabIndex="0">
              Each of the ADVANCE CRT partner universities shares this
              commitment to an open and inclusive environment. They have
              received award recognition for their involvement as part of the
              AthenaSWAN Charter. Further information on each of our partner
              universities’ efforts in EDI can be found below:
            </Typography>
            <Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <a href="https://www.ucc.ie/en/edi/">
                  <ListItem>University College Cork</ListItem>
                </a>
                <a href="https://www.cit.ie/edi ">
                  <ListItem>Munster Technological University</ListItem>
                </a>
                <a href="https://www.tcd.ie/equality/training/">
                  <ListItem>Trinity College Dublin</ListItem>
                </a>
                <a href="https://www.dit.ie/edi/">
                  <ListItem>Technological University Dublin</ListItem>
                </a>
                <a href="https://www.maynoothuniversity.ie/edi/edi-policies/EDpolicy">
                  <ListItem>Maynooth University</ListItem>
                </a>
              </List>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default EDIStatementPage;
