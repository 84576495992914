import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import PersonPaper from "../components/personPaper";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,

    position: "absolute",
  },
});

const PeoplePage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Our People";
  const data = [];
  const [studentList, setStudentList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [activeStudentList, setActiveStudentList] = useState([]);
  const [activeSupersisorList, setActiveSupervisorList] = useState([]);
  const [ActiverPersonList, setActivePersonList] = useState([]);
  const [university, setUniversity] = React.useState(0);
  const [position, setPosition] = React.useState(0);

  useEffect(() => {
    const getStudents = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getstudents")
        .then((response) => {
          const studentData = response.data;
          setStudentList(studentData);
          setActiveStudentList(studentData);

          for (var i = 0; i <= studentData.length - 1; i++) {
            data.push(studentData[i]);
            orderData(data);
          }
          orderData(data);
        });
    };
    getStudents();
  }, []);

  useEffect(() => {
    const getSupervisors = () => {
      axios
        .get(
          "https://advanceserver-45066d4d7734.herokuapp.com/getsupervisorprofiles"
        )
        .then((response) => {
          const supervisorData = response.data;

          setSupervisorList(supervisorData);
          setActiveSupervisorList(supervisorData);

          for (var i = 0; i <= supervisorData.length - 1; i++) {
            data.push(supervisorData[i]);
          }
          orderData(data);
        });
    };
    getSupervisors();
  }, []);

  useEffect(() => {
    const getAdmins = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getadmins")
        .then((response) => {
          const adminData = response.data;

          setAdminList(adminData);

          for (var i = 0; i <= adminData.length - 1; i++) {
            data.push(adminData[i]);
          }
          orderData(data);
        });
    };
    getAdmins();
  }, []);

  useEffect(() => {
    const generateData = () => {
      setActivePersonList(data);
      setPersonList(data);
    };
    generateData();
  }, []);

  async function orderData(d) {
    d.sort(function (a, b) {
      if (a.surname < b.surname) {
        console.log(a.surname);
        return -1;
      }
      if (a.surname > b.surname) {
        return 1;
      }
      return 0;
    });
    return d;
  }

  async function handleUniversityChange(event) {
    const uniHandler = [];
    setUniversity(event.target.value);
    setActivePersonList([]);

    if (position === 0) {
      if (event.target.value > 0) {
        for (var i = 0; i <= personList.length - 1; i++) {
          if (personList[i].universityID === event.target.value) {
            uniHandler.push(personList[i]);
          }
        }
        orderData(uniHandler);
        setActivePersonList(uniHandler);
      } else {
        orderData(personList);

        setActivePersonList(personList);
      }
    } else if (position === 1) {
      if (event.target.value > 0) {
        for (var i = 0; i <= studentList.length - 1; i++) {
          if (studentList[i].universityID === event.target.value) {
            uniHandler.push(studentList[i]);
          }
        }
        orderData(uniHandler);
        setActivePersonList(uniHandler);
      } else {
        orderData(studentList);

        setActivePersonList(studentList);
      }
    } else if (position === 2) {
      if (event.target.value > 0) {
        for (var i = 0; i <= supervisorList.length - 1; i++) {
          if (supervisorList[i].universityID === event.target.value) {
            uniHandler.push(supervisorList[i]);
          }
        }
        orderData(uniHandler);
        setActivePersonList(uniHandler);
      } else {
        orderData(supervisorList);

        setActivePersonList(supervisorList);
      }
    } else if (position === 3) {
      if (event.target.value > 0) {
        for (var i = 0; i <= supervisorList.length - 1; i++) {
          if (
            supervisorList[i].universityID === event.target.value &&
            supervisorList[i].isExec
          ) {
            uniHandler.push(supervisorList[i]);
          }
        }
        orderData(uniHandler);

        setActivePersonList(uniHandler);
      } else {
        for (var i = 0; i <= supervisorList.length - 1; i++) {
          if (supervisorList[i].isExec) {
            uniHandler.push(supervisorList[i]);
            orderData(uniHandler);

            setActivePersonList(uniHandler);
          }
        }
      }
    } else if (position === 4) {
      if (event.target.value > 0) {
        orderData(adminList);

        setActivePersonList(adminList);
      }
    } else {
      orderData(personList);

      setActivePersonList(personList);
    }
  }

  async function handlePositionChange(event) {
    const positionHandler = [];
    setPosition(event.target.value);
    setActivePersonList([]);

    if (university != 0) {
      if (event.target.value == 1) {
        for (var i = 0; i <= studentList.length - 1; i++) {
          if (studentList[i].universityID === university) {
            positionHandler.push(studentList[i]);
          }
        }
        orderData(positionHandler);

        setActivePersonList(positionHandler);
      } else if (event.target.value == 2) {
        for (var i = 0; i <= supervisorList.length - 1; i++) {
          if (supervisorList[i].universityID === university) {
            positionHandler.push(supervisorList[i]);
          }
        }
        orderData(positionHandler);

        setActivePersonList(positionHandler);
      } else if (event.target.value == 3) {
        for (var i = 0; i <= supervisorList.length - 1; i++) {
          if (
            supervisorList[i].universityID === university &&
            supervisorList[i].isExec === 1
          ) {
            positionHandler.push(supervisorList[i]);
          }
        }
        orderData(positionHandler);

        setActivePersonList(positionHandler);
      } else if (event.target.value == 4) {
        orderData(adminList);

        setActivePersonList(adminList);
      } else {
        for (var i = 0; i <= personList.length - 1; i++) {
          if (personList[i].universityID === university) {
            positionHandler.push(personList[i]);
          }
        }
        orderData(positionHandler);

        setActivePersonList(positionHandler);
      }
    } else if (event.target.value === 1) {
      orderData(studentList);

      setActivePersonList(studentList);
    } else if (event.target.value === 2) {
      orderData(supervisorList);

      setActivePersonList(supervisorList);
    } else if (event.target.value === 3) {
      var management = [];

      for (var i = 0; i <= supervisorList.length - 1; i++) {
        if (supervisorList[i].isExec === 1) {
          management.push(supervisorList[i]);
        }
      }
      orderData(management);

      setActivePersonList(management);
    } else if (event.target.value === 4) {
      orderData(adminList);

      setActivePersonList(adminList);
    } else {
      orderData(personList);

      setActivePersonList(personList);
    }
  }

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.dropDownContainer}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">University</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={university}
                label="University"
                onChange={handleUniversityChange}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>University College Cork</MenuItem>
                <MenuItem value={2}>Munster Technological University</MenuItem>
                <MenuItem value={3}>Trinity College Dublin</MenuItem>
                <MenuItem value={4}>Technological University Dublin</MenuItem>
                <MenuItem value={5}>Maynooth University</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={4}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Position</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={position}
                label="Position"
                onChange={handlePositionChange}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Students</MenuItem>
                <MenuItem value={2}>Supervisors</MenuItem>
                <MenuItem value={3}>Management Team</MenuItem>
                <MenuItem value={4}>Operations Team</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container className={classes.dropDownContainer}>
        {ActiverPersonList.map((m) => (
          <>
            <PersonPaper props={m} />
          </>
        ))}
        ;
      </Grid>

      <BottomBanner />
    </>
  );
};

export default PeoplePage;
