import * as React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function People(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const colour = props.props;
  const navigate = useNavigate();

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => navigate("/people")}
        color={colour}
      >
        People
      </Button>
    </div>
  );
}
