import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "./carousel.css";

const useStyles = makeStyles({
  leftButton: {
    position: "absolute",
    top: "50%",
    color: "#ffffff",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    zIndex: 1,
    left: "10px",
  },
  rightButton: {
    color: "#ffffff",
  },
  test: {
    position: "absolute",
    top: "50%",
    color: "#ffffff",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    zIndex: 1,
    left: "10px",
  },
});

const Carousel = ({ images }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel">
      <div
        className="carousel-inner"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <div key={index} className="slide">
            <img src={image} alt={"Carousel image showing ADVANCE students"} />
          </div>
        ))}
      </div>
      <button
        className="prev-button"
        onClick={prevSlide}
        aria-label="Previous Carousel Image"
      >
        <ArrowBackIosIcon />
      </button>
      <button
        className="next-button"
        onClick={nextSlide}
        aria-label="Previous Carousel Image"
      >
        <ArrowForwardIosIcon />
      </button>
    </div>
  );
};

export default Carousel;
