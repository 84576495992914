import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import "./menuDrawer.css";

export default function MenuDrawer() {
  const [state, setState] = React.useState({
    top: false,
  });
  const [openAbout, setOpenAbout] = React.useState(false);
  const [openOutputs, setOpenOutputs] = React.useState(false);
  const [openTraining, setOpenTraining] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);
  const navigate = useNavigate();

  const anchor = "top";

  const handleClickAbout = () => {
    setOpenAbout(!openAbout);
  };

  const handleClickOutputs = () => {
    setOpenOutputs(!openOutputs);
  };

  const handleClickTraining = () => {
    setOpenTraining(!openTraining);
  };

  const handleClickContact = () => {
    setOpenContact(!openContact);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" ? "auto" : 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        <ListItem>
          <ListItemButton onClick={handleClickAbout}>
            <ListItemText primary="About Us" />
            {openAbout ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <ListItem nested>
          <Collapse in={openAbout} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/about");
                }}
              >
                <ListItemText primary="About ADVANCE" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/edi");
                }}
              >
                <ListItemText primary="EDI Statement" />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate("/people")}>
            <ListItemText primary="People" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={handleClickOutputs}>
            <ListItemText primary="Research Outputs" />
            {openOutputs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <ListItem nested>
          <Collapse in={openOutputs} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/posters");
                }}
              >
                <ListItemText primary="Student Posters" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/newsletters");
                }}
              >
                <ListItemText primary="ADVANCE Newsletters" />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={handleClickTraining}>
            <ListItemText primary="Training & Research" />
            {openTraining ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <ListItem nested>
          <Collapse in={openTraining} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/research");
                }}
              >
                <ListItemText primary="Research Programme" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText
                  primary="Training Programme"
                  onClick={() => {
                    navigate("/training");
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText
                  primary="Placement Programme"
                  onClick={() => {
                    navigate("/placement");
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText
                  primary="Previous Events"
                  onClick={() => {
                    navigate("/previousevents");
                  }}
                />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={handleClickContact}>
            <ListItemText primary="Contact Us" />
            {openContact ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <ListItem nested>
          <Collapse in={openContact} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/contactus");
                }}
              >
                <ListItemText primary="Contact Details" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate("/partnerships");
                }}
              >
                <ListItemText primary="Partnerships" />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <button
          onClick={toggleDrawer(anchor, true)}
          className="button"
          aria-label="Menu Dropdown"
        >
          <MenuIcon className="iconmenu" />
        </button>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
