import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import BottomBanner from "../components/bottomBanner";
import EventVideoPaper from "../components/eventVideoPaper";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
});

const PreviousEventsPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Previous ADVANCE Events";
  const data = [
    {
      id: 1,
      title: "ADVANCE CRT Summer School 2022, Maynooth",
      description:
        "Between 20th and 23rd June 2022, the ADVANCE CRT ran a Summer School in Systems Thinking for our researchers. The goal of the Summer School was to give researchers the chance to think systemically about how the subject of their research fits in the real world, and what change systems thinking and practice can bring about.",
      url: "https://www.youtube.com/embed/XA1Q00hlQQw?si=g7ZJhhR4HhCpCGBJ",
    },
    {
      id: 2,
      title:
        "Future Professional Skills Showcase 2023 in conjunction with CRT AI, Dublin",
      description:
        "SFI Centre for Research Training in Artificial Intelligence (CRT in AI) and SFI Centre for Research Training in Advanced Networks for Sustainable Societies (ADVANCE CRT) jointly organised the Future Professional Skills Showcase 2023 (FPSS’23) on the 21st and 22nd February in the RIU Gresham Hotel and The Round Room at the Mansion House, Dublin. Over 15 industry partners: IBM, BNY Mellon, danalto, Collins Aerospace, Enable, HEAnet, Huawei, Jaguar Land Rover, Keelvar, KPMG, Saint John of God-Community Services, Smart Dublin, Trellix, Xperi and MasterCard, and more than 200 participants attended the event.",
      url: "https://www.youtube.com/embed/0mECfz4Imec?si=RU49GHDxC1DxW5Rq",
    },
    {
      id: 3,
      title: "ADVANCE CRT Summer School 2023, Kilkenny",
      description:
        "Between 6th and 9th June 2023, ADVANCE CRT ran a Summer School in the history town of Kilkenny focusing on Design Thinking. A foundation in Design Thinking should allow our researchers to create sustainable, inclusive, and socially informed technological innovations",
      url: "https://www.youtube.com/embed/PVlRsRDfAMk?si=6mwNhQ38SgZG33tj",
    },
    {
      id: 4,
      title:
        "Future Professional Skills Showcase 2024 in conjunction with CRT AI and d-Real, Cork",
      description:
        "ADVANCE CRT, CRT-AI, and d-Real jointly organised the Future Professional Skills Showcase 2024 (FPSS’24) on the 6th and 7th February in the Marina Markey and Pairc Uí Chaoimh, Cork. Seventeen industry partners: Abbott, Alpha Wealth, Analog Devices, Arlo, Cadence Design Systems, Centre for Excellence in Universal Design (CEUD), Collins Aerospace, Commscope, Dell, Eli Lilly, HEAnet, Huawei, IBM, Jaguar Land Rover, Keelvar, Qualcomm, Smart Dublin, and more than 200 participants attended the event.",
      url: "https://www.youtube.com/embed/h_kjisrBde8?si=MQbdLHxKHZvqORqN",
    },
  ];
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          {data.reverse().map((m) => (
            <EventVideoPaper props={m} />
          ))}
        </Grid>

        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>
      <BottomBanner />
    </>
  );
};

export default PreviousEventsPage;
