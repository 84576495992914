import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TopBar from "../components/topBar";
import TitleBanner from "../components/titleBanner";
import BottomBanner from "../components/bottomBanner";
import PdfDownloader from "../components/pdfDownloader";
import axios, { Axios } from "axios";

import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,

    position: "absolute",
  },
  stack: {
    position: "realtive",
  },
});

const PosterPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "ADVANCE Student Posters";
  const [dbposters, setDbposters] = useState([]);

  useEffect(() => {
    const getPosters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getposters")
        .then((response) => {
          const posterData = response.data;
          setDbposters(posterData);
        });
    };
    getPosters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container className={classes.stack}>
        {dbposters.map((m) => (
          <PdfDownloader props={m} />
        ))}
      </Grid>
      <BottomBanner />
    </>
  );
};

export default PosterPage;
