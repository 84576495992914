import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useNavigate } from "react-router-dom";

export default function SiteNavBox() {
  const navigate = useNavigate();

  return (
    <Box>
      <List component="div">
        <ListItemText>Site Navigation</ListItemText>
        <ListItemButton
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemText>Home</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate("/people");
          }}
        >
          <ListItemText>People</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate("/newsletters");
          }}
        >
          <ListItemText>Newsletter</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate("/posters");
          }}
        >
          <ListItemText>Posters</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate("/contactus");
          }}
        >
          <ListItemText>Contact</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
}
