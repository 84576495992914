import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PdfDownloader from "./pdfDownloader";

const useStyles = makeStyles({
  box: {
    padding: "20px 30px",
    width: "100%",
    height: "100%",
    borderLeft: 1,
    textAlign: "center",
  },
});

export default function SideBar(props) {
  const classes = useStyles();
  const data = props.props;

  return (
    <Box className={classes.box} sx={{ borderLeft: 1 }}>
      {!!data.isNewsletter && (
        <Typography variant="h3" tabIndex="0">
          Latest Newsletter
        </Typography>
      )}
      {!!data.isPoster && (
        <Typography variant="h3" tabIndex="0">
          {data.title}'s Research
        </Typography>
      )}

      <PdfDownloader props={data} />
    </Box>
  );
}
