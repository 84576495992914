import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import "./pdfDownloader.css";

const useStyles = makeStyles({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  },
});

export default function PdfDownloader(props) {
  const classes = useStyles();
  const title = props.props.title;
  const url = props.props.url;
  const image = props.props.image;
  const isNewsletter = props.props.isNewsletter;
  const isPoster = props.props.isPoster;

  const [logoOpacity, setLogoOpacity] = useState("0%");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  function hoverHandlerIn() {
    setLogoOpacity("100%");
  }

  function hoverHandlerOut() {
    setLogoOpacity("0%");
  }

  return (
    <>
      <Paper className="paper">
        <Box className="box">
          {!!isNewsletter && (
            <img
              src={require(`../downloadables/newsletters/images/${image}`)}
              className="img"
              alt={title}
              tabIndex="1"
            />
          )}
          {!!isPoster && (
            <img
              src={require(`../downloadables/posters/images/${image}`)}
              className="img"
              alt={title}
              tabIndex="1"
            />
          )}
          <Box className="text">
            <Typography
              className="text"
              color="#00008b"
              variant="subtitle1"
              tabIndex="0"
            >
              {title}
            </Typography>
          </Box>
          <button
            onClick={handleOpen}
            className="iconbuttonzoom"
            aria-label="Zoom First Page"
          >
            <ZoomInIcon
              className="iconzoom"
              opacity={logoOpacity}
              alt="Zoom First Page"
            />
          </button>
          {!!isNewsletter && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              aria-label="View Newsletter in Canva in new tab"
            >
              <button
                className="iconbuttondownload"
                aria-label="View Newsletter in Canva in new tab"
              >
                <DownloadIcon className="icondownload" />
              </button>
            </a>
          )}
          {!!isPoster && (
            <a
              href={require(`../downloadables/posters/pdfs/${url}`)}
              aria-label="View Poster in new tab"
            >
              <button
                className="iconbuttondownload"
                aria-label="View Poster in new tab"
              >
                <DownloadIcon className="icondownload" />
              </button>
            </a>
          )}
        </Box>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          {!!isNewsletter && (
            <img
              src={require(`../downloadables/newsletters/images/${image}`)}
              className="imgmodal"
            />
          )}
          {!!isPoster && (
            <img
              src={require(`../downloadables/posters/images/${image}`)}
              className="imgmodal"
            />
          )}
        </Box>
      </Modal>
    </>
  );
}
