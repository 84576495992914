import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TopBar from "../components/topBar";
import BottomBanner from "../components/bottomBanner";
import url from "../downloadables/newsletters/pdfs/2023May.pdf";
import image from "../downloadables/newsletters/images/2023may.png";
import url2 from "../downloadables/newsletters/pdfs/2023Jun.pdf";
import image2 from "../downloadables/newsletters/images/2023jun.png";
import url3 from "../downloadables/newsletters/pdfs/2023Jul.pdf";
import image3 from "../downloadables/newsletters/images/2023jul.png";
import url4 from "../downloadables/newsletters/pdfs/2023Aug.pdf";
import image4 from "../downloadables/newsletters/images/2023aug.png";
import url5 from "../downloadables/newsletters/pdfs/2023Sep.pdf";
import image5 from "../downloadables/newsletters/images/2023sep.png";
import PdfDownloader from "../components/pdfDownloader";
import { Stack, Grid, Typography } from "@mui/material";
import MenuDrawer from "../nav/menuDrawer";
import SiteNavBox from "../components/siteNavBox";

const useStyles = makeStyles({
  container: {
    //backgroundImage: `url(${Image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  paperContainer: {
    //backgroundPosition: "top left",
    //backgroundSize: "100% 70vh",
    //backgroundRepeat: "no-repeat",
    //backgroundImage: `url(${background})`,
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    //right: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  carousel: {
    //zIndex: -1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    //position: "absolute",
    left: "35%",
    //bottom: "50%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,

    position: "absolute",
    //width: "100%",
  },
  stack: {
    position: "realtive",
    //display:"flex"
  },
});

const TestPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Test Page";
  const newsletters = [
    {
      title: "May 2023 Newsletter",
      url: url,
      image: image,
    },
    {
      title: "June 2023 Newsletter",
      url: url2,
      image: image2,
    },
    {
      title: "July 2023 Newsletter",
      url: url3,
      image: image3,
    },
    {
      title: "August 2023 Newsletter",
      url: url4,
      image: image4,
    },
    {
      title: "Sept 2023 Newsletter",
      url: url5,
      image: image5,
    },
  ];

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.stack}>
        {newsletters.map((m) => (
          <PdfDownloader props={m} />
        ))}
      </Grid>

      <SiteNavBox />
      <MenuDrawer />

      <BottomBanner />
    </>
  );
};

export default TestPage;
