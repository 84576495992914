import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import partnershipimage from "../images/handshake.png";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
  image: {
    width: "100%",
  },
});

const PartnershipPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Partnerships";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="body1" tabIndex="0">
              The ADVANCE CRT brings together over 50 research supervisors from
              computer science, electrical and computer engineering, social
              science, psychology, and health science, to stimulate
              socially-responsible and inclusive creativity and innovation in
              the field of future networks and the Internet of Things. ADVANCE
              is a close partnership between five Irish Higher Education
              Institutions and Irish-based industry. ADVANCE is a rich ecosystem
              of partner universities involving University College Cork, Cork
              Institute of Technology, Maynooth University, Trinity College
              Dublin, and TU Dublin and large companies such as Nokia, Intel,
              DellEMC, u-blox, Vodafone, UTRC, Johnson Controls, JaguarLandRover
              and indigenous SMEs such as Taoglas, Decawave, Shimmer, Independet
              Living Ireland and local authorities and NGOs such as Dublin and
              Cork City Councils, RehabGroup and Enable Ireland.{" "}
            </Typography>

            <br aria-hidden="true" />
            <img
              src={partnershipimage}
              className={classes.image}
              alt="Partnership image"
            />

            <Typography variant="body1" tabIndex="0">
              We welcome contact from organisations in the industry and the
              voluntary sectors who wish to partner with us in training the next
              generation of highly skilled scientists and engineers through
              supporting work placements, research project definition, and
              training courses. If you are interested, please contact as at
              advance-crt@cs.ucc.ie
            </Typography>
            <br aria-hidden="true" />

            <Typography variant="body1" tabIndex="0">
              ADVANCE leverages complimentary research initiatives, particularly
              the SFI-funded CONNECT Centre for Future Networks.
              Internationally, we are engaged with a network of research groups
              from Universities and Research Institutes from around the world
              that will offer our students placement opportunities, such as
              Imperial College, TU Eindhoven, RWTH Aachen, University of
              Toronto, University of Auckland, IMDEA, and CEA.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default PartnershipPage;
