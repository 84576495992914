import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import banner from "../images/UCC.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  box: {
    width: "100%",
    height: "40vh",
    backgroundColor: "#00008B",
    position: "relative",
  },
  bannerGrid: {
    display: "flex",
    position: "relative",
  },
  bannerBox: {
    backgroundColor: "#000000",
  },
  bannerImage: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    left: "0px",
  },
});

export default function BottomBanner() {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 1, sm: 1 }}>
          <img src={banner} className={classes.bannerImage} alt="HEI Logos" />
        </Grid>
        <Grid item xs={0} sm={0} md={6} lg={6} />
      </Grid>
    </Box>
  );
}
