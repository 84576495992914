import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  paper: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minheight: "420px",
    marginBottom: 10,
  },
  box: {
    width: "320px",
    height: "300px",
    padding: 5,
    paddingTop: 10,
    borderRadius: 4,
    margin: "15px 15px 10px 15px",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 4,
  },
  paragraph: {
    paddingLeft: 10,
    margin: 2,
  },
  name: {
    paddingTop: 30,
    margin: 2,
    paddingLeft: 10,
  },
  email: {
    paddingBottom: 30,
    margin: 2,
    paddingLeft: 10,
  },
  button: {
    margin: "5px 0px 5px 125px",
  },
  textGrid: {
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
  },
  bioGrid: {
    display: "block",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "40px",
    marginInlineEnd: "40px",
  },
});

export default function TestProfilePaper(props) {
  const classes = useStyles();
  const person = props.props;
  const universities = [
    "Other",
    "University College Cork",
    "Munster Technological University",
    "Trinity College Dublin",
    "Technological University Dublin",
    "Maynooth University",
  ];
  const bio = person.bio.substring(1, person.bio.length - 1);
  const [image, setImage] = useState("profile.png");
  const [primarySupervisor, setPrimarySupervisor] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  async function handleImage() {
    if (person.profileImage) {
      setImage(person.profileImage);
    }
  }

  useEffect(() => {
    handleImage();
    findStudentRelations(person.uid);
  }, []);

  async function findStudentRelations(data) {
    var foundPrimaries = [];
    var foundSecondaries = [];
    console.log("called");
    axios
      .post(
        "https://advanceserver-45066d4d7734.herokuapp.com/getstudentsupervisors",
        { uid: data }
      )
      .then(function (response) {
        console.log(response.data);
        for (var i = 0; i <= response.data.length - 1; i++) {
          console.log("supervisor " + i + " is " + response.data[i].supervisor);
          if (response.data[i].relation == 0) {
            foundPrimaries.push(response.data[i]);
          } else {
            foundSecondaries.push(response.data[i]);
          }
        }
        setPrimarySupervisor(foundPrimaries);
        setSupervisors(foundSecondaries);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6.5}
          order={{ xs: 1, sm: 1, md: 1 }}
        >
          <Box className={classes.box}>
            <img
              src={require(`../profileImages/${image}`)}
              className={classes.image}
              alt={`Image of ${person.firstName + " " + person.surname}`}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5.5}
          order={{ xs: 2, sm: 2, md: 2 }}
          className={classes.textGrid}
        >
          <Typography className={classes.name} variant="subtitle1" tabIndex="0">
            Name
          </Typography>
          <Typography className={classes.paragraph} tabIndex="0">
            {person.firstName + " " + person.surname}
          </Typography>
          <Typography
            className={classes.paragraph}
            variant="subtitle1"
            tabIndex="0"
          >
            University
          </Typography>
          <Typography className={classes.paragraph} tabIndex="0">
            {universities[person.universityID]}
          </Typography>
          <Typography
            className={classes.paragraph}
            variant="subtitle1"
            tabIndex="0"
          >
            Email
          </Typography>
          <Typography className={classes.email} tabIndex="0">
            <a href={`mailto:${person.email}`}>{person.email}</a>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.bioGrid}>
          <Typography
            variant="subtitle1"
            className={classes.paragraph}
            tabIndex="0"
          >
            Biography
          </Typography>
          <Typography
            className={classes.paragraph}
            style={{ whiteSpace: "pre-line" }}
            tabIndex="0"
          >
            {bio}
          </Typography>
        </Grid>
        {!!person.isStudent && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.bioGrid}
          >
            <Typography
              variant="subtitle1"
              className={classes.paragraph}
              tabIndex="0"
            >
              Primary Supervisor
            </Typography>

            {primarySupervisor.map((m) => (
              <Typography
                className={classes.paragraph}
                style={{ whiteSpace: "pre-line" }}
                tabIndex="0"
              >
                {m.supervisor}, {universities[m.supervisorHEI]}
              </Typography>
            ))}
            <Typography
              variant="subtitle1"
              className={classes.paragraph}
              tabIndex="0"
            >
              Supervisory Team
            </Typography>

            {supervisors.map((m) => (
              <Typography
                className={classes.paragraph}
                style={{ whiteSpace: "pre-line" }}
                tabIndex="0"
              >
                {m.supervisor}, {universities[m.supervisorHEI]}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
