import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import firstImage from "../images/bannerimage04.png";
import secondImage from "../images/bannerimage05.png";
import thirdImage from "../images/bannerimage06.png";
import { Typography, List, ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TopBar from "../components/topBar";
import Carousel from "../components/carousel/carousel";
import BottomBanner from "../components/bottomBanner";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../components/videos.css";

const useStyles = makeStyles({
  container: {
    position: "relative",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    margin: "0 0 10px 0",
  },
  paperContainer: {
    zIndex: -1,
    display: "flex",
    alignItems: "flex-end",
  },
  homeTextContainer: {
    position: "relative",
    margin: "10px 0 0 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenucontainer: {
    width: "100%",
    position: "relative",
    zIndex: 2,
    backgroundColor: "#ffffff",
  },
  navmenu: {
    width: "100%",
  },
  carousel: {
    zIndex: -1,
    height: "100%",
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    padding: 10,
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
});

const HomePage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const images = [firstImage, secondImage, thirdImage];
  const [dbnewsletters, setDbnewsletters] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(process.env.REACT_APP_DB_HOST);

    const getNewsletters = () => {
      console.log(process.env.REACT_APP_DB_USER);
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });
          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <Grid container className={classes.navmenucontainer}>
        <Grid item xs={12}>
          <TopBar className={classes.topBar} />
        </Grid>

        <Grid item xs={12}>
          <NavMenu props={colour} className={classes.navmenu}></NavMenu>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Box className={classes.paperContainer}>
            <Carousel className={classes.carousel} images={images} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.homeTextContainer}>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Grid container>
              <Grid item>
                <Typography variant="h3" tabIndex="0">
                  What is ADVANCE CRT?
                </Typography>
                <br aria-hidden="true" />
                <Typography variant="body1" tabIndex="0">
                  The ADVANCE CRT is a doctoral Centre for Research Training in
                  Advanced Networks for Sustainable Societies. ADVANCE CRT is a
                  partnership funded by Science Foundation Ireland (SFI) between
                  5 Irish Universities:
                </Typography>

                <Typography tabIndex="0">
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>University College Cork</ListItem>
                    <ListItem>Munster Technological University</ListItem>
                    <ListItem>Trinity College Dublin</ListItem>
                    <ListItem>Technological University Dublin</ListItem>
                    <ListItem>Maynooth University</ListItem>
                  </List>
                </Typography>
                <Typography variant="body1" tabIndex="0">
                  The Centre offers a 4-year cohort-based PhD programme. We aim
                  to train 120+ students in advanced networking, Internet of
                  Things, machine learning, and other digital technologies with
                  applications in sustainable and assisted living and health.
                </Typography>
                <br aria-hidden="true" />
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/PVlRsRDfAMk?si=6mwNhQ38SgZG33tj"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Grid>
              <Grid item className={classes.button}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/about");
                  }}
                >
                  Read More
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>
      <BottomBanner />
    </>
  );
};

export default HomePage;
